import { Box, Typography } from '@mui/material';
import { queryClient, useCreateMutation, useIndexQuery, useUpdateMutation } from 'api';
import { Loader } from 'components/Loader';
import PageContainer from 'components/PageContainer';
import { Banner } from 'components/banner/banner';
import { Info } from 'components/icons';
import { useCurrentRole, useCurrentRoleOrThrow } from 'hooks/slices';
import { useOnErrorNavigation } from 'hooks/useOnErrorNavigation';
import { useTranslation } from 'locales/i18n';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  CreateLgoSettingParams,
  UpdateLgoSettingParams,
} from 'types/api/pharmacies/panelist_connection';
import { ConnectPanelistCard } from './ConnectPanelistCard';
import { PanelistConnectedCard } from './PanelistConnectedCard';
import { PanelistConnectionStatusIcon } from './PanelistStatusComponents';

export const useGetPanelistConnectionStatus = () => {
  const currentRole = useCurrentRole();

  const { data, isLoading } = useIndexQuery(
    'pharmacies/panelist_connection/status',
    {},
    { enabled: currentRole?.resource_type === 'Pharmacy' }
  );

  return {
    panelistConnection: data,
    isPharmacyAllowedToEstablishConnection: data?.status !== 'not_allowed',
    panelist: data?.pharmacy_panelist?.panelist,
    isLoading: isLoading,
  };
};

export const PharmacyPanelistConnectionPage = () => {
  const { t } = useTranslation();
  const { handleErrorNavigation } = useOnErrorNavigation();

  const [isLoading, setIsLoading] = useState(false);
  const currentRole = useCurrentRoleOrThrow();

  const createLgoSettingMutation = useCreateMutation('pharmacies/panelist_connection');
  const updateLgoSettingMutation = useUpdateMutation('pharmacies/panelist_connection');

  const { panelistConnection, isPharmacyAllowedToEstablishConnection } =
    useGetPanelistConnectionStatus();

  const onCreateLgoSettingHandler = (lgoSettingParams: CreateLgoSettingParams) => {
    setIsLoading(true);

    createLgoSettingMutation
      .mutateAsync(lgoSettingParams)
      .then(() => {
        toast.success(t('pharmacyLgoConnection.connectionSuccess'));
        queryClient.invalidateQueries('pharmacies/panelist_connection/status');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onUpdateLgoSettingHandler = (lgoSettingParams: UpdateLgoSettingParams) => {
    setIsLoading(true);

    updateLgoSettingMutation
      .mutateAsync(lgoSettingParams)
      .then(() => {
        if (lgoSettingParams.fields.pharmacy_lgo_setting.panelist) {
          toast.warning(t('pharmacyLgoConnection.connectionSuccess'));
        } else {
          toast.success(t('pharmacyLgoConnection.logoutSuccess'));
        }
        queryClient.invalidateQueries('pharmacies/panelist_connection/status');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const isUserNotPharmacyOwner = currentRole.kind !== 'pharmacy_owner';

  useEffect(() => {
    if (!panelistConnection && !isPharmacyAllowedToEstablishConnection) {
      handleErrorNavigation(403);
    }
  }, [panelistConnection, isPharmacyAllowedToEstablishConnection, handleErrorNavigation]);

  if (!panelistConnection) return <Loader />;

  return (
    <PageContainer
      pageTitle={t('shared.panelistConnection')}
      pageTitleBadge={
        <PanelistConnectionStatusIcon status={panelistConnection.status} fontSize="medium" />
      }
    >
      {isUserNotPharmacyOwner && (
        <Banner
          icon={<Info size={30} />}
          title={
            <Box ml={1}>
              <Typography>
                <Trans
                  i18nKey="pharmacyLgoConnection.askOwnerToCreateOne"
                  components={{ bold: <strong /> }}
                />
              </Typography>
            </Box>
          }
          color="warning"
          sx={{ mb: 2 }}
        />
      )}
      {panelistConnection.pharmacy_panelist?.panelist ? (
        <PanelistConnectedCard
          connectedLgoSettings={panelistConnection.pharmacy_panelist}
          panelistConnection={panelistConnection}
          handleOnUpdate={onUpdateLgoSettingHandler}
          disableDisconnectButton={isUserNotPharmacyOwner}
          isLoading={isLoading}
        />
      ) : (
        <ConnectPanelistCard
          pharmacyPanelist={panelistConnection.pharmacy_panelist}
          handleOnCreate={onCreateLgoSettingHandler}
          handleOnUpdate={onUpdateLgoSettingHandler}
          disableConnectButton={isUserNotPharmacyOwner}
          isLoading={isLoading}
        />
      )}
    </PageContainer>
  );
};
export default PharmacyPanelistConnectionPage;
