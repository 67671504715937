export const DOWNLOAD_LINKS = {
  APP_STORE: 'https://apple.co/3C4IliB',
  PLAY_STORE: 'https://bit.ly/appFaks',
};

export const LGO_LINKS = {
  DATA_POLICY: 'https://www.faks.co/vie-privee',
  INTERCOM: 'https://intercom.help/faks/fr/articles/7984665-connexion-lgo',
  ONBOARDINGS: {
    ospharm: 'https://bienvenue.ospharm.org/8',
    offisante: 'https://offisante.fr/',
    santestat: 'https://www.santestat.fr/',
  },
};

export const ospharmWelcomeLink = (cip: string | null) =>
  cip ? `https://bienvenue.ospharm.org/8/${cip}` : 'https://bienvenue.ospharm.org/8';

export const openInNewTab = (link: string) => window.open(link, '_blank');
