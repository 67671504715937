import { StyledLink } from 'components/link/styles';
import { useCurrentPharmacyOrThrow } from 'hooks/slices';
import { t } from 'locales/i18n';
import { Trans } from 'react-i18next';
import { Panelist } from 'types/models/pharmacy_lgo_settings';
import { ospharmWelcomeLink } from 'utils/linkUtils';
import { PANELIST_SUPPORT_PHONE_NUMBER } from '../../../constants';

type Props = {
  panelist: Panelist;
};

export const PanelistConnectionSteps = ({ panelist }: Props) => {
  const currentPharmacy = useCurrentPharmacyOrThrow();

  return (
    <>
      {t('pharmacyLgoConnection.panelistConnectionSteps.title')}
      <ul style={{ marginLeft: 20 }}>
        <li>{t(`pharmacyLgoConnection.panelistConnectionSteps.${panelist}.first`)}</li>
        <li>
          {t(`pharmacyLgoConnection.panelistConnectionSteps.${panelist}.second`) + ' '}
          {panelist === 'ospharm' && (
            <StyledLink href={ospharmWelcomeLink(currentPharmacy.cip)} target="_blank">
              {t('pharmacyLgoConnection.panelistConnectionSteps.ospharm.clickHereToAuthorize')}
            </StyledLink>
          )}
        </li>
        <li>
          <Trans
            i18nKey={`pharmacyLgoConnection.panelistConnectionSteps.${panelist}.last`}
            components={[<strong />]}
            values={{
              phoneNumber: PANELIST_SUPPORT_PHONE_NUMBER[panelist],
            }}
          />
        </li>
      </ul>
    </>
  );
};
